<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterId
      class="mt-4"
      @input="setKundeMasterIdFilter"
      :optionsArray="kundeMasterIdFilterOptions"
      :value="kundeMasterIdFilter"
      :storeFunction="getKundenFilterFunction"
      :placeholder="$t('DUBLETTEN.FILTER.KUNDENNUMMER')"
      objectName="Kunde"
    ></FilterId>
    <FilterKundeMasterValidationStateKunde
      class="mt-4"
      :placeholder="$t('KUNDEN.VALIDATIONSTATEKUNDE')"
      @input="setValidationStateKundeFilter"
      :value="validationStateKundeFilter"
    />
    <b-form-checkbox
      class="mt-4"
      @input="setAtleastOneKundeFilter"
      :checked="atleastOneKundeFilter"
      switch
      size="md"
    >
      {{ $t('KUNDEN.MINDESTENSEINEDUBLETTE') }}
    </b-form-checkbox>
    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button
        :disabled="isCurrentFilterApplied"
        lg
        block
        @click="onClickSaveFilter($event)"
        variant="primary"
      >
        {{ $t('COMMON.FILTERANWENDEN') }}
      </b-button>
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter()"
        v-show="isAnyFilterApplied"
        v-t="'REISEN.COMMON.RESET'"
      ></b-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import {
  RESET_FILTER,
  SET_KUNDE_MASTER_ID_FILTER,
  GET_KUNDE_MASTER_ID_FILTER_OPTIONS,
  SET_KUNDE_MASTER_VALIDATION_STATE_KUNDE_FILTER,
  SET_KUNDE_MASTER_ALEAST_ONE_KUNDE_FILTER,
  SET_IS_CURRENT_FILTER_APPLIED,
  SAVED_FILTER,
} from '@/core/kunden/stores/kunden.module';
import FilterId from '@/components/common/filter-id.vue';
import FilterKundeMasterValidationStateKunde from '@/components/kunden/filter-kunde-master-validation-state-kunde.vue';

export default {
  components: {
    FilterId,
    FilterKundeMasterValidationStateKunde,
  },
  computed: {
    ...mapState({
      isAnyFilterApplied: state => state.kunden.isAnyFilterApplied,
      isCurrentFilterApplied: state => state.kunden.isCurrentFilterApplied,
      kundeMasterIdFilterOptions: state => state.kunden.kundeMasterIdFilterOptions,
      kundeMasterIdFilter: state => state.kunden.filter.kundeMasterIdFilter,
      validationStateKundeFilter: state => state.kunden.filter.validationStateKundeFilter,
      atleastOneKundeFilter: state => state.kunden.filter.atleastOneKundeFilter,
    }),
  },
  methods: {
    getKundenFilterFunction(id) {
      return this.$store.dispatch(GET_KUNDE_MASTER_ID_FILTER_OPTIONS, { idFilter: id });
    },
    onClickSaveFilter() {
      this.$store.dispatch(SAVED_FILTER);
    },
    onClickResetFilter() {
      this.$store.dispatch(RESET_FILTER);
    },
    setKundeMasterIdFilter(id) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_KUNDE_MASTER_ID_FILTER, id);
    },
    setValidationStateKundeFilter(state) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_KUNDE_MASTER_VALIDATION_STATE_KUNDE_FILTER, state);
    },
    setAtleastOneKundeFilter(isAtleastOneKunde) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_KUNDE_MASTER_ALEAST_ONE_KUNDE_FILTER, isAtleastOneKunde);
    },
  },
};
</script>
