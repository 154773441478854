<template>
  <div>
    <Multiselect
      :value="value"
      track-by="title"
      label="title"
      :options="options"
      :placeholder="placeholder"
      :title="placeholder"
      :show-labels="false"
      :multiple="true"
      @input="$emit('input', $event)"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span :title="option.title" class="multiselect__tag"
          >{{ value.length > 2 ? option.title.substring(0, 3) + '...' : option.title }}
          <i @click.stop="remove(option)" aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i>
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <span class="badge badge-secondary">{{ props.option.title }}</span>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  props: {
    placeholder: { type: String },
    value: { type: Array },
  },
  data() {
    return {
      options: [
        {
          title: this.$t('KUNDEN.Validated'),
          class: 'secondary',
          filter: { validationStateKunde: 'Validated' },
        },
        {
          title: this.$t('KUNDEN.PartlyValidated'),
          class: 'secondary',
          filter: { validationStateKunde: 'PartlyValidated' },
        },
        {
          title: this.$t('KUNDEN.NotValidated'),
          class: 'secondary',
          filter: { validationStateKunde: 'NotValidated' },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}
</style>
