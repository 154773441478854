<template>
  <div class="card card-custom" :style="heightComponentInlineStyle">
    <div class="card-body d-flex flex-column justify-content-between">
      <b-table
        table-class="kunden-table"
        :key="'b-table-kunden-table'"
        :id="'b-table-kunden-table'"
        :ref="'b-table-kunden-table'"
        :empty-filtered-text="$t('COMMON.EMPTYFILTEREDTEXT', { name: 'Kunden' })"
        :sempty-text="$t('COMMON.EMPTYTEXT', { name: 'Kunden' })"
        show-empty
        responsive
        primary-key="id"
        :items="kunden"
        :fields="fields"
        :sort-by="orderBy.split(' ')[0]"
        :sort-desc="orderBy.split(' ')[1] === 'desc'"
        :sort-direction="orderBy.split(' ')[1]"
        :sticky-header="tableHeight + 'px'"
        :busy="isKundenLoading"
        no-local-sorting
        no-sort-reset
        @sort-changed="$emit('sortChange', $event)"
      >
        <template #cell(state)="row">
          <b-badge :id="'popover-validationState-' + row.item.id">{{
            displayNullOrTranslate(row.item.validationStateKunde, row.item.id, false)
          }}</b-badge>
          <b-popover :target="'popover-validationState-' + row.item.id" triggers="hover" boundary="viewport">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th class="p-0"></th>
                  <th class="p-0"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-muted">{{ $t('COMMON.ADRESSE') }}:</td>
                  <td>
                    {{
                      displayNullOrTranslate(
                        row.item.validationStateAdresse,
                        row.item.strasse || row.item.postleitzahl || row.item.ort || row.item.land,
                        true
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-muted">{{ $t('COMMON.TELEFONMOBIL') }}:</td>
                  <td>
                    {{
                      displayNullOrTranslate(
                        row.item.validationStateTelefonMobil,
                        row.item.telefonMobil,
                        true
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-muted">{{ $t('COMMON.TELEFONPRIVAT') }}:</td>
                  <td>
                    {{
                      displayNullOrTranslate(
                        row.item.validationStateTelefonPrivat,
                        row.item.telefonPrivat,
                        true
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-muted">{{ $t('COMMON.TELEFONGESCHAEFTLICH') }}:</td>
                  <td>
                    {{
                      displayNullOrTranslate(
                        row.item.validationStateTelefonGeschaeftlich,
                        row.item.telefonGeschaeftlich,
                        true
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-popover>
        </template>

        <template #cell(name)="row">
          {{ row.item.anrede }} {{ row.item.titel }} {{ row.item.vorname }} {{ row.item.nachname }}
        </template>
        <template #cell(adresse)="row">
          {{ row.item.strasse }}, {{ row.item.postleitzahl }} {{ row.item.ort }} {{ row.item.land }}
        </template>

        <template #cell(kunden)="row">
          {{ getKundenIdArray(row.item, true).join(', ') || '-' }}
        </template>

        <template #cell(actions)="row">
          <ActionButton
            @click="onToggleDetailsClick($event, row)"
            :iconClass="row.detailsShowing ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
            title="Ausklappen"
          />
          <ActionButton
            :link="
              'https://crmplus.zoho.eu/skrreisengmbh/index.do/cxapp/crm/org20070234763/search?searchword=' +
              row.item.kundennummer +
              '&sModules=Leads%2CContacts'
            "
            iconClass="fas fa-users"
            title="Zoho"
          />
          <ActionButton
            :link="{ name: 'kunden-view', params: { kundenId: row.item.id } }"
            :title="$t('REISEN.COMMON.DETAILS')"
            iconClass="fas fa-info"
          />
        </template>

        <template slot="row-details" slot-scope="row" v-if="row.item._showDetails">
          <KundenMasterTableDetails :kundenIdArray="getKundenIdArray(row.item)"></KundenMasterTableDetails>
        </template>
      </b-table>
      <SKRPagination
        v-model="currentPage"
        :isLoading="isKundenLoading"
        :totalRows="allKundenCount"
        :currentRows="kunden.length || 0"
        @pageChange="onPageChange"
      ></SKRPagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.b-table-sticky-header > .table.b-table > thead > tr > th) {
  position: sticky !important;
}
.table-dropdown > .btn {
  padding: 0;
}
</style>

<script>
import KundenMasterTableDetails from '@/components/kunden/master-table-details.vue';
import SKRPagination from '@/components/common/skr-pagination.vue';
import ActionButton from '@/components/common/action-button.vue';

export default {
  components: {
    SKRPagination,
    KundenMasterTableDetails,
    ActionButton,
  },
  props: {
    kunden: {
      type: Array,
    },
    orderBy: {
      type: String,
    },
    allKundenCount: { type: Number },
    isKundenLoading: {
      type: Boolean,
    },
    tableHeight: { type: Number, required: false },
    isInitiallyLoading: {
      type: Boolean,
    },
    heightComponentInlineStyle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'kundennummer',
          label: this.$t('DUBLETTEN.FILTER.KUNDENNUMMER'),
          sortable: true,
        },
        {
          key: 'updatedDateUtc',
          label: this.$t('COMMON.GEANDERT'),
          formatter: value => {
            return this.$options.filters.dateTime(value);
          },
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('REISEN.COMMON.NAME'),
        },
        {
          key: 'email',
          label: this.$t('AUTH.INPUT.EMAIL'),
        },
        {
          key: 'adresse',
          label: this.$t('COMMON.ADRESSE'),
        },
        {
          key: 'telefonPrivat',
          label: this.$t('COMMON.TELEFONPRIVATSHORT'),
        },
        {
          key: 'telefonGeschaeftlich',
          label: this.$t('COMMON.TELGESCHAEFTLICHSHORT'),
        },
        {
          key: 'telefonMobil',
          label: this.$t('COMMON.TELMOBILSHORT'),
        },
        {
          key: 'kunden',
          label: this.$t('DUBLETTEN.DUBLETTEN'),
          sortable: true,
        },
        {
          key: 'state',
          label: this.$t('REISEN.COMMON.STATE'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('MENU.ACTIONS'),
        },
      ],
    };
  },
  methods: {
    displayNullOrTranslate(validationState, isColumnSet, isPopup = false) {
      const translationString = (isPopup ? 'KUNDEN.POPUP.' : 'KUNDEN.') + validationState;
      return validationState && isColumnSet
        ? this.$t(translationString)
        : isColumnSet && !validationState
        ? 'Nicht validiert'
        : '-';
    },
    getKundenIdArray(kundeMaster, removeOwnId = false) {
      // Remove own id from kunden array and then join all ids to one array
      return kundeMaster.kunden
        .filter(kunde => !removeOwnId || kunde.id !== kundeMaster.kundennummer)
        .map(kunde => kunde.id);
    },
    onPageChange($event) {
      this.$emit('pageChange', $event);
      this.$refs['b-table-kunden-table'].$el.scrollTop = 0;
    },
    onToggleDetailsClick($event, row) {
      row.toggleDetails();
    },
  },
};
</script>
