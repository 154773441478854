<template>
  <div>
    <TableAggregationsToolbar
      v-if="!isLoading && aggregations"
      :aggregations="aggregations"
    ></TableAggregationsToolbar>
    <b-skeleton v-if="isLoading" width="300px" height="24px"></b-skeleton>
  </div>
</template>

<script>
import TableAggregationsToolbar from '@/components/common/table-aggregations-toolbar';
import apiService from '@/core/common/services/api.service';

export default {
  components: { TableAggregationsToolbar },
  props: {
    filter: {
      type: Array,
    },
    isParentLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      lastFilter: null,
      aggregations: null,
    };
  },
  watch: {
    isParentLoading: {
      immediate: true,
      handler: async function (isLoading) {
        if (isLoading) {
          await this.refreshData(this.lastFilter);
        }
      },
    },
    filter: {
      immediate: true,
      handler: async function (f) {
        await this.refreshData(f);
        this.lastFilter = f;
      },
    },
  },
  methods: {
    async refreshData(f) {
      const odataAggregate = await this.getAggregationsByFilter(f);
      const transformedAggregate = this.transformAggregateDate(odataAggregate);
      this.aggregations = [
        {
          data: transformedAggregate.Validated,
          title: this.$t('KUNDEN.Validated'),
          iconClass: 'fas fa-check-double text-success',
        },
        {
          data: transformedAggregate.PartlyValidated,
          title: this.$t('KUNDEN.PartlyValidated'),
          iconClass: 'fas fa-check text-grey',
        },
        {
          data: transformedAggregate.NotValidated,
          title: this.$t('KUNDEN.NotValidated'),
          iconClass: 'fas fa-ban text-danger',
        },
        {
          data: transformedAggregate.total,
          title: this.$t('COMMON.INSGESAMT'),
          iconClass: 'fas fa-user text-primary',
        },
      ];
    },
    async getAggregationsByFilter() {
      this.isLoading = true;
      return apiService
        .get(
          'KundeMaster?$apply=filter(isDeleted eq false and kunden/any())/groupby(((validationStateKunde)),aggregate($count as $count))&$count=true'
        )
        .then(response => {
          return response.data.result.value;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    transformAggregateDate(data) {
      return data.reduce(
        (acc, cur) => {
          if (!acc.hasOwnProperty(cur.validationStateKunde)) {
            acc[cur.validationStateKunde] = cur.$count;
            acc.total += cur.$count;
          }
          return acc;
        },
        { total: 0 }
      );
    },
  },
};
</script>
