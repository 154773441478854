<template>
  <div>
    <Multiselect
      v-model="selected"
      :multiple="true"
      :close-on-select="true"
      :showLabels="false"
      :limit="3"
      :limitText="limitText"
      :options="optionsArray"
      @input="$emit('input', $event)"
      @search-change="onSearchChange"
      :loading="isLoading"
      :placeholder="placeholder"
      track-by="id"
      label="label"
    >
      <template #noOptions>Mind. {{ startSearchAtLength }} Ziffern der {{ placeholder }} eingeben</template>
      <template #noResult>Keine {{ objectName }} gefunden. Ändere deine Suche</template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  data() {
    return { selected: null, isLoading: false };
  },
  props: {
    optionsArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: { type: Array, default: () => [] },
    placeholder: { type: String },
    objectName: { type: String },
    startSearchAtLength: { type: String, default: '3' },
    storeFunction: { type: Function },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (v) {
        this.selected = v === null ? [] : v;
      },
    },
  },
  methods: {
    onSearchChange(t) {
      if (!this.isLoading) {
        this.isLoading = true;
        this.storeFunction(t).finally(() => {
          this.isLoading = false;
        });
      }
    },
    limitText(count) {
      return `+${count} ${this.objectName}`;
    },
  },
};
</script>
