<template>
  <div class="row m-0">
    <div class="col-md-12 gutter-b">
      <div class="card card-custom card-fit card-border">
        <div class="card-header">
          <h3 class="card-title font-weight-bolder" v-t="'REISEN.COMMON.VORGAENGE'"></h3>
          <div class="card-toolbar"></div>
        </div>
        <div class="card-body">
          <VorgangTable
            :isLoading="isLoading"
            :displayFieldsByKey="[
              'id',
              'reisezeitraum',
              'reiseterminkuerzel',
              'kunde',
              'zimmer',
              'npx',
              'agentur.id',
              'brutto',
              'stateSlot',
              'isReleased',
              'actions',
            ]"
            @update-single-item="onUpdateSingleItem"
            :items="vorgaenge"
          >
            <template #stateSlot="row">
              <VorgangStateWidget :state="row.item.blankState" />
            </template>
          </VorgangTable>
          <b-skeleton-table v-if="isLoading" :rows="5" :columns="6"></b-skeleton-table>
          <LoadSpinnerWidget
            v-if="!isLoading"
            :isLoading="isLoading"
            :dataAvailable="vorgaenge.length > 0"
          ></LoadSpinnerWidget>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.more-btn {
  cursor: pointer;
}
table#vorgaengeTable > thead > tr > th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>

<script>
import * as odataService from '@/core/common/services/odata.service';

import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';

import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

export default {
  name: 'ReiseterminTableDetails',
  components: { LoadSpinnerWidget, VorgangTable, VorgangStateWidget },
  props: {
    kundenIdArray: { type: Array },
  },
  data() {
    return {
      vorgaenge: [],
      isLoading: true,
      expand: {
        hotelleistungen: {
          filter: {
            isDeleted: false,
          },
          expand: {
            Reisetermin: {
              filter: {
                isDeleted: false,
              },
              expand: { Reise: {}, pax: {} },
            },
          },
        },
        agentur: { select: ['id'] },
        kunde: {
          expand: {
            master: {
              expand: { kunden: {} },
            },
          },
        },
      },
    };
  },
  async mounted() {
    this.vorgaenge = await this.getVorgaenge(this.kundenIdArray);
  },
  methods: {
    getVorgaenge(idArray) {
      const filter = {
        kunde: {
          id: {
            in: idArray,
          },
        },
      };
      return odataService
        .getVorgang({ expand: this.expand, filter, count: true })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.log(error);
          return error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async onUpdateSingleItem(index, id) {
      const newVorgang = await this.getVorgangById(id);
      this.$set(this.vorgaenge, index, newVorgang);
    },
    async getVorgangById(id) {
      this.isLoading = true;
      const filter = { id: id };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
