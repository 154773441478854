<template>
  <div>
    <FilterOverview title="Übersicht" :isInitiallyLoading="isInitiallyLoading" @reload="onReload">
      <template #toolbar-left>
        <KundeMasterTableToolbarBase :filter="kundeMasterFilterArray" :isParentLoading="isBusy" />
      </template>
      <template #filter> <KundeMasterFilter /></template>
      <template #table="{ tableHeight, heightComponentInlineStyle }">
        <KundeOverviewTable
          :tableHeight="tableHeight"
          :heightComponentInlineStyle="heightComponentInlineStyle"
          :kunden="kunden"
          :orderBy="orderBy"
          :isInitiallyLoading="isInitiallyLoading"
          :isKundenLoading="isBusy"
          :allKundenCount="count"
          @pageChange="onPageChange"
          @sortChange="onSortChange"
        ></KundeOverviewTable>
      </template>
    </FilterOverview>
  </div>
</template>

<style lang="scss" scoped>
.card-filter-nav {
  overflow-y: auto;
}
</style>
<script>
import { GET_KUNDEN, KUNDEN_SORT_CHANGED, KUNDEN_PAGE_CHANGED } from '@/core/kunden/stores/kunden.module';
import { mapState, mapGetters } from 'vuex';

import KundeOverviewTable from '@/components/kunden/master-overview-table.vue';
import KundeMasterTableToolbarBase from '@/components/kunden/master-table-toolbar-base.vue';
import FilterOverview from '@/components/common/filter-overview.vue';

import KundeMasterFilter from '@/components/kunden/master-filter.vue';
export default {
  name: 'KundeOverviewComponent',
  components: {
    KundeOverviewTable,
    KundeMasterFilter,
    KundeMasterTableToolbarBase,
    FilterOverview,
  },
  computed: {
    ...mapState({
      kunden: state => state.kunden.kunden,
      top: state => state.kunden.top,
      skip: state => state.kunden.skip,
      count: state => state.kunden.count,
      isBusy: state => state.kunden.isBusy,
      isInitiallyLoading: state => state.kunden.isInitiallyLoading,
      orderBy: state => state.kunden.orderBy,
      filter: state => state.kunden.filter,
      expand: state => state.kunden.expand,
    }),
    ...mapGetters(['kundeMasterFilterArray']),
  },
  async mounted() {
    this.$store.dispatch(GET_KUNDEN);
  },
  methods: {
    onReload() {
      this.$store.dispatch(GET_KUNDEN);
    },
    async onSortChange(ctx) {
      this.$store.dispatch(KUNDEN_SORT_CHANGED, { sortContext: ctx });
    },
    async onPageChange(ctx) {
      this.$store.dispatch(KUNDEN_PAGE_CHANGED, { pageContext: ctx });
    },
  },
};
</script>
